.navbar {
    display: flex;
    justify-content: space-between;
    background-color: #8FB9E0;
    color: #fff;
    padding: 5px;
}

.navbarContainer {
    display: flex;
    align-items: center;
}

.accountName {
    line-height: 50px;
    font-weight: 700;
    padding-left: 10px;
}

.profileImage {
    border-radius: 50%;
}

.icon {
    font-size: 30px;
    margin-right: 10px;
    cursor: pointer;
}