.timelineitem {
    background-color: #D5DDF6  !important;
    border-width: 0;
    border-radius: 5px !important;
    padding-left: 10px;
    box-shadow: 1px 1px 3px #ccc;
}


.prodBg {
    background-color: #ADDDCE !important;
    color: #333 !important;
    border: 1px solid #ADDDCE !important;
}

.areaBg {
    background-color: #d3d5d6 !important;
    color: #818181 !important;
    border: 1px solid #d3d5d6 !important;
}

.filter {
    width: 150px;
    padding: 20px;
}

.filterWide{
    width: 300px;
    padding: 20px;
}

.choicegroup{
    padding: 10px;
}

.itemCalendar span {
    font-size: 12px !important;
}

.itemCalendar p {
    font-size: 12px !important;
}
.itemCalendar strong {
    font-size: 15px !important;
}

.itemCalendar{
    line-height: 5px !important;
    text-align: left !important;
}